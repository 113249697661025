<template>
	<v-card>
		<v-card-title>
			<template v-if="userId">
				编辑用户
			</template>
			<template v-else>
				创建新用户
			</template>
		</v-card-title>
		<v-divider class="mb-7"></v-divider>
		<v-card-text>
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				@submit.prevent="onFormSubmit"
			>
				<v-text-field
					v-model="name"
					:counter="3"
					outlined
					:rules="nameRules"
					label="用户名"
					required
				></v-text-field>
				<v-text-field
					v-model="email"
					:rules="requiredRules"
					outlined
					label="用户邮箱"
					required
				></v-text-field>
				<v-text-field
					v-model="phone"
					:rules="requiredRules"
					outlined
					label="用户电话"
					required
				></v-text-field>
				<v-text-field
					:value="computedDateFormattedDatefns"
					clearable
					:rules="requiredRules"
					label="出生日期"
					outlined
					readonly
					required
					@click="dateMenu = true"
					@click:clear="start_date = null"
				></v-text-field>
				<v-dialog v-model="dateMenu" max-width="290">
					<v-date-picker
						v-model="start_date"
						:first-day-of-week="0"
						show-current
						locale="zh-cn"
						class="pb-6"
						@change="dateMenu = false"
					></v-date-picker>
				</v-dialog>
				<v-select
					v-model="role"
					:items="roles"
					outlined
					label="用户角色"
					hide-details
					required
				></v-select>
				<template v-if="userId">
					<v-checkbox
						v-model="updatePassword"
						label="更换密码"
					></v-checkbox>
					<v-text-field
						v-if="updatePassword"
						v-model="password"
						:append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
						:rules="[rules.required, rules.min]"
						:type="showpassword ? 'text' : 'password'"
						label="输入新密码"
						hint="密码至少包含8个字符"
						outlined
						@click:append="showpassword = !showpassword"
						counter
					></v-text-field>
				</template>
				<template v-else>
					<v-text-field
						v-model="password"
						:append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
						:rules="[rules.required, rules.min]"
						:type="showpassword ? 'text' : 'password'"
						label="请输入您的密码"
						hint="密码至少包含8个字符"
						class="mt-8"
						outlined
						@click:append="showpassword = !showpassword"
						counter
					></v-text-field>
				</template>
				<div class="d-flex justify-space-between mt-4">
					<v-btn
						color="error"
						text
						@click="reset"
						>
						重新填写
					</v-btn>
					<v-btn
						:disabled="!valid"
						color="success"
						elevation="0"
						@click="validate"
						type="submit"
					>
						{{submitText}}
					</v-btn>
				</div>
			</v-form>
		</v-card-text>
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
	</v-card>
</template>

<script>
import UserServices from '../../services/User';
import {  parse, format } from 'date-fns';

export default {
	name: 'userForm',
	data: () => ({
		valid: true,
		name: '',
		nameRules: [
			v => !!v || '请输入项目名称',
			v => (v && v.length >= 3) || '项目名称至少3个字',
		],
		requiredRules: [
			v => !!v || '必填内容',
		],
		email: '',
		password: '',
		showpassword: false,
		rules: {
			required: value => !!value || '这是必填内容.',
			min: v => v.length >= 8 || '密码至少8个字符长度',
			emailMatch: () => (`您输入的登陆邮箱或密码错误`),
		},
		role: '',
		user_id: null,
		roles: ["admin", "site-manager"],
		phone: '',
		start_date: '',
		date_of_birth: '',
		dateMenu: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		overlay: false,
		updatePassword: false
	}),
	props: {
		userId: {
			type: Number,
			required: false,
			default: null
		},
	},
	watch: {
		start_date(val){
			this.date_of_birth = this.formatDate(val);
		},
		userId(){
			this.getUser();
		}
	},
	computed: {
		computedDateFormattedDatefns () {
			return this.start_date ? this.formatDate(this.start_date) : ''
		},
		submitText(){
			return this.userId ? '更新用户' : '添加用户';
		}
	},
	created(){
		if(this.userId){
			this.getUser();
		}
	},
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		formatDate (date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here

			if(this.user_id){
				const body = {
					"email": this.email,
					"name": this.name,
					"role": this.role,
					"phone": this.phone,
					"date_of_birth": this.date_of_birth
				}
				if( this.updatePassword ) {
					body.password = this.password;
				}
	
				this.updateUser(body);
			} else {
				const body = {
					"email": this.email,
					"name": this.name,
					"password": this.password,
					"role": this.role,
					"phone": this.phone,
					"date_of_birth": this.date_of_birth
				}
				this.createUser(body);
			}
		},
		async getUser(){
			try {
				let response = await UserServices.getUserById(this.userId);

				if(response.data.statusCode === 200){
					const userData = response.data.data;
					this.name = userData.name;
					this.email = userData.email;
					this.phone = userData.phone;
					this.role = userData.role;
					this.user_id = userData.id;
					let formatDate = parse(userData.date_of_birth, 'dd/MM/yyyy', new Date());
					this.start_date = format(formatDate, 'yyyy-MM-dd');
				}
			}catch(error){
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.overlay = false;
				this.snackbar.model = true;
			}
		},
		async updateUser(body){
			try {
				let response = await UserServices.updateUser(this.user_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `用户${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.overlay = false;
				this.snackbar.model = true;
			}
		},
		async createUser(body){
			try {
				let response = await UserServices.createUser(body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `用户${response.data.message}`;
					this.snackbar.color = 'success';
						this.overlay = false;
						this.snackbar.model = true;
						this.reset();
						setTimeout(()=>{
							this.snackbar.model = false;
							this.$emit('on-submit-success');
						}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.overlay = false;
					this.snackbar.model = true;
			}
		}
	}
}
</script>
