<template>
	<v-sheet>
		<v-toolbar flat>
			<v-toolbar-title>用户列表</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn 
				v-if="user && user.role === 'admin'"
				color="primary"
				@click="dialogCreateUser = true"
				elevation="0">
				<v-icon>mdi-plus</v-icon> 添加新用户
			</v-btn>
		</v-toolbar>
		<v-divider></v-divider>		
		<div class="d-flex justify-end py-2 pr-4">
			<v-divider vertical class="mx-3"></v-divider>
			<div style="max-width:150px;">
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						color="primary"
						text
						small
						tile
						v-bind="attrs"
						v-on="on"
						>
							每页显示 {{itemsPerPage}}
						<v-icon small>
							mdi-menu-down
						</v-icon>
						</v-btn>
					</template>
					<v-list flat dense>
						<v-list-item
							v-for="(item, index) in items"
							:key="index"
							@click="itemsPerPage = parseInt(item, 10)"
							>
						<v-list-item-subtitle class="body-2">{{ item }}</v-list-item-subtitle>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>
		<v-divider></v-divider>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<v-data-table
				:headers="headers"
				:items="users"
				:items-per-page="itemsPerPage"
				:page.sync="currentPage"
				hide-default-footer
				@update:options="onUpdatePageOptions"
				>
				<template v-slot:item.actions="{item}">
					<v-btn 
						color="primary"
						elevation="0"
						class="mr-2"
						small
						@click="updateUser(item.id)"
					>
						<v-icon small left>
							mdi-eye
						</v-icon>
						编辑信息
					</v-btn>
				</template>
			</v-data-table>
			<v-divider></v-divider>
			<div class="d-flex justify-center py-2 ">
				<v-pagination v-model="currentPage" :length="totalPages" @input="onUpdatePage"></v-pagination>
			</div>
			<v-divider></v-divider>
			<v-dialog v-model="dialogCreateUser" max-width="600">
				<user-form 
				 	@on-submit-success="onUserCreated"
				 />
			</v-dialog>
			<v-dialog v-model="dialogEditUser" max-width="600">
				<user-form :userId="activeUserId"
					@on-submit-success="onUserUpdated"
				 />
			</v-dialog>
		</template>
	</v-sheet>
</template>

<script>
import UserServices from '../../services/User';
import UserForm from '../../components/User/UserForm';

import { mapState } from 'vuex';

export default {
	name: 'account',
	components: {
		UserForm,
	},
	computed: {
		...mapState('user', ['user'],),
	},
	data: () => ({
		users: [],
		itemsPerPage: 5,
		totalPages: 1,
		currentPage: 1,
		items: [5, 10, 15, 20],
		status: 0,
		headers: [
			{ text: '编码', value: 'id',align: 'start', },
			{ text: '用户名', value: 'name' },
			{ text: '邮箱', value: 'email' },
			{ text: '用户角色', value: 'role' },
	        { text: '功能', value: 'actions', sortable: false, width: 120 }
		],
		dialogCreateUser: false,
		dialogEditUser: false,
		loading: false,
		activeUserId: null
	}),
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		}
	},
	methods: {
		async getUsers(){
			try {
				let response = await UserServices.getUserList(this.currentPage, this.itemsPerPage, this.status)
				this.users = response.data.data.items;
				this.itemsPerPage = response.data.data.max_items_per_page;
				this.totalPages = response.data.data.total_pages;
				this.currentPage = response.data.data.current_page;
				
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		onUpdatePageOptions(option){
			this.itemsPerPage = option.itemsPerPage;
			this.currentPage = option.page;
			this.getUsers();
		},
		onUpdatePage(page){
			this.currentPage = page;
			this.getUsers();
		},
		updateUser(user_id){
			this.activeUserId = user_id;
			this.dialogEditUser = true;
		},
		onUserCreated(){
			this.dialogCreateUser = false;
			this.loading = true;
		},
		onUserUpdated(){
			this.dialogEditUser = false;
			this.loading = true;
		}
	},
	created(){
		this.loading = true;
		this.getUsers(1,10,0);
	}
}
</script>
